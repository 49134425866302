/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { history, getPath, getValueOfAttribut } from 'helpers';
import { userActions, platformActions } from 'actions';
import logo from 'styles/assets/images/White.svg';
import { changePlatform } from 'helpers/Platform';
import withlandingstyle from './WithLandingStyle';
import { platformConstants } from '../../constants';

const { HAS_DRY_PRODUCTS_KEY, HAS_FROZEN_PRODUCTS_KEY, HAS_FRESH_PRODUCTS_KEY } = platformConstants;
class LandingPage extends React.Component {
  componentDidMount() {
    const { userInfo, fetchPlatforms, handleClickLogout } = this.props;
    if (!userInfo || !Object.keys(userInfo).length) {
      handleClickLogout();
    } else {
      fetchPlatforms();
    }
  }

  showTemperatures(platform) {
    const { attributs } = platform;
    const hasDryProducts = Number(getValueOfAttribut(attributs || [], HAS_DRY_PRODUCTS_KEY));
    const hasFreshProducts = Number(getValueOfAttribut(attributs || [], HAS_FRESH_PRODUCTS_KEY));
    const hasFrozenProducts = Number(getValueOfAttribut(attributs || [], HAS_FROZEN_PRODUCTS_KEY));
    const temperatures = [];
    if (hasDryProducts) {
      temperatures.push(
        <div className="landing__headline-temperatures">
          <i className="Circle__icon-yellow icon-dry" />
          <span className="landing__headline-decription">Sec</span>
        </div>
      );
    }
    if (hasFreshProducts) {
      temperatures.push(
        <div className="landing__headline-temperatures">
          <i className="Circle__icon-green icon-apple" />
          <span className="landing__headline-decription">Frais</span>
        </div>
      );
    }
    if (hasFrozenProducts) {
      temperatures.push(
        <div className="landing__headline-temperatures">
          <i className="Circle__icon-blue icon-snow" />
          <span className="landing__headline-decription">Surgelé</span>
        </div>
      );
    }
    return temperatures;
  }

  render() {
    const { className, handleClickLogout, platforms, user } = this.props;
    const isDesactivated = platforms.some((plateform) => plateform.is_deactivate);
    return (
      <div className={className}>
        <div className="landing__background fade-in">
          <div>
            <div className="landing__container">
              <img className="landing__logo" src={logo} alt="logo in white" />
              <h6 className="landing__logo-text">Commande.com</h6>
            </div>
            <span className="landing_logout-icon" onClick={handleClickLogout}>
              <span className="landing_logout">DÉCONNEXION</span>
              <span className="button-circular__icon">
                <i className="icon-logout-landing" />
              </span>
            </span>
          </div>
          <div className="landing__provider">
            {isDesactivated ? (
              <div className="landing__disclaimer">
                <p className="landing__label">
                  Bonjour,
                  <br />
                  <br />
                  Important : une nouvelle version <a href="https://commande.com/">
                    commande.com
                  </a>{' '}
                  est disponible.
                  <br />
                  <br />
                  Veuillez {/* @todo : replace URL by V3 when available */}
                  <a href="https://www-v3.commande.com/">
                    cliquer ICI pour accéder à la nouvelle version de commande.com
                  </a>
                </p>
              </div>
            ) : (
              <>
                <div className="landing__container-label">
                  <h6 className="landing__label">Sélection du fournisseur :</h6>
                </div>
                <div className="landing__provider-wrapper">
                  {platforms.map((platform) => (
                    <span
                      className="landing__provider-container fade-in-with-translate-y"
                      onClick={() => changePlatform(platform.id, user)}
                    >
                      <div className="landing__provider-content">
                        <span className="landing__headline">{platform.name}</span>
                        {this.showTemperatures(platform)}
                      </div>
                    </span>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { platform, user } = state;
  const { platforms } = platform;
  return {
    user,
    userInfo: user.information,
    platforms,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPlatforms: () => {
    dispatch(platformActions.fetchPlatforms());
  },
  handleClickLogout: () => {
    dispatch(userActions.logout());
    history.push(getPath('login'));
  },
});

LandingPage.propTypes = {
  handleClickLogout: PropTypes.func.isRequired,
  className: PropTypes.string,
  fetchPlatforms: PropTypes.func.isRequired,
  platforms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(withlandingstyle(LandingPage));
