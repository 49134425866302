import styled from 'styled-components';

import background from 'styles/assets/images/Connexion.png';

export default (Component) => styled(Component)`
  background: url(${background});
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;

  .landing__background {
    text-align: center;
    width: 100%;
    padding: 40px;
  }

  .landing__logo {
    width: 100px;
    height: 100px;
  }

  .landing__logo-text {
    font-family: Montserrat;
    font-size: 24px;
    color: azure;
    display: block;
    font-weight: 600;
    margin-top: 15px;
  }

  .landing__label {
    font-size: 14px;
    color: azure;
    font-family: Montserrat;
    display: block;
    margin: 0;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-left: 10px;

    a {
      color: white;
    }
  }

  .landing__headline {
    color: #ffffff;
    font-weight: bold;
    display: inline-block;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .landing__headline-decription {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 14px;
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
  }

  .landing_logout {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0.15px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }

  .button-circular__icon {
    line-height: 40px;
    text-align: center;
    font-size: 15px;
    height: 40px;
    width: 40px;
    background-color: #ff0066;
    border-radius: 40px;
    text-decoration: none;
    vertical-align: middle;
    display: inline-block;
  }

  .landing_logout-icon {
    top: 40px;
    position: absolute;
    right: 40px;
    cursor: pointer;
  }
  .icon-logout-landing:before {
    color: #f5f5f5;
    content: '\\e92f';
  }

  .landing__icon-yellow {
    background-color: #eddd0c;
    border-radius: 24px;
    height: 24px;
    width: 24px;
    line-height: 24px;
    font-size: 11px;
    margin-right: 15px;
    display: inline-block;
  }

  .landing__icon-green {
    background-color: #35e687;
    border-radius: 24px;
    height: 24px;
    width: 24px;
    line-height: 24px;
    margin-right: 15px;
    font-size: 11px;
    display: inline-block;
  }

  .landing__icon-blue {
    background-color: #63c8ff;
    border-radius: 24px;
    height: 24px;
    width: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 11px;
    margin-right: 15px;
    display: inline-block;
  }

  .landing__container {
    display: block;
  }

  .landing__container-label {
    display: block;
    text-align: left;
  }

  .landing__provider {
    width: 100%;
    margin-top: 70px;
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
  }

  .landing__provider-wrapper {
    flex-flow: nowrap;
    justify-content: space-between;
    display: flex;
  }

  .landing__provider-container {
    font-size: 10px;
    background-color: #1b74ab;
    border-radius: 20px;
    width: 240px;
    min-height: 160px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    .landing__provider-content {
      margin: 0 auto;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      .landing__headline-temperatures {
        i {
          margin-right: 10px;
          &::before {
            height: 25px;
            width: 25px;
            line-height: 25px;
          }
        }
        width: 7rem;
        text-align: left;
        margin-bottom: 5px;
      }
    }
  }

  .landing__disclaimer {
    padding: 30px 20px;
    margin-top: 20px;
    background-color: #1b74ab;
    border-radius: 20px;
    color: white;
  }

  @media screen and (max-width: 768px) {
    .landing__provider-wrapper {
      flex-flow: wrap;
      justify-content: center;
    }
    .landing__provider-container {
      width: calc(50% - 20px);
    }
    .landing_logout {
      display: none;
    }
  }

  @media screen and (max-width: 544px) {
    .landing__provider-wrapper {
      flex-flow: wrap;
      justify-content: center;
    }
    .landing__provider-container {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
`;
